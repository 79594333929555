<template>
  <div>
    <div class="soundimage-container">
      <img class="sound-thumb" :height="height" :width="width" :src="`data:image/svg+xml;utf8,${soundImage}`">

      <div class="text-overlay-background" :style="`height:${height}px;width:${width}px;overflow:hidden;`" @click="playPause()" >

        <div class="play-pause-container">
          <div class="play-pause-button">
            <font-awesome-icon v-show="!isPlaying" class="play-pause" :icon="['fas', 'play']" />
            <font-awesome-icon v-show="isPlaying" class="play-pause" :icon="['fas', 'pause']" />
            <audio ref="audio" :src="mp3Audio"></audio>
          </div>
        </div>

      </div>
    </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
import { generateFromString } from 'generate-avatar'

export default {
  name: 'SoundImage',
  props: {
    songObject: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      default: 100
    },
    width: {
      type: Number,
      default: 100
    },
    previewSoundName: String,
    previewSoundArtist: String
  },
  data() {
    return {
      isPlaying: false,
    };
  },
  methods: {
    playPause() {
      if (this.isPlaying) {
        this.$refs.audio.pause();
        this.isPlaying = false;
      } else {
        this.$refs.audio.play();
        this.isPlaying = true;
      }
    }
  },
  computed: {
    soundImage() {
      return generateFromString(this.songObject.id.toString());
    },
    mp3Audio() {
      return this.songObject.mp3_file.slice(1, -1);
    },
    soundName() {
      if (this.previewSoundName) {
        return this.previewSoundName;
      } else {
        return this.songObject.song_name;
      }
    },
    soundArtist() {
      if (this.previewSoundArtist) {
        return this.previewSoundArtist;
      } else {  
        return this.songObject.artist_name;
      }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa-play{
  filter:drop-shadow(0px 5px 18px rgb(9, 9, 9))
}
.fa-pause{
  filter:drop-shadow(0px 5px 18px rgb(9, 9, 9))
}
.fa-play:active{
  filter:drop-shadow(0px 5px 27px rgb(9, 9, 9))
}
.fa-pause:active{
  filter:drop-shadow(0px 5px 27px rgb(9, 9, 9))
}
.play-pause-button {    
  color: white;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%,-50%);
  font-size: 36px;
}
.play-pause-button:active {    
  color: white;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%,-50%);
  font-size: 30px;
}
.play-pause-container {
  position:relative;
  height: 100%;
}
.names {
  position:absolute;
  bottom:5px;
  right:12px;
  text-align: right;
}
.sound-image-h2 {
  color:white;
  font-size:27px;
  margin:0;
  font-weight: 700;
}
.sound-image-h3 {
  color:white;
  font-size:18px;
}
.soundimage-container {
  min-height:100px;
  position: relative;
}
.text-overlay-background {
  cursor: pointer;
  background-color:rgba(0, 0, 0, 0.1);
  background-image:
    radial-gradient(
                  rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.15)
    );  
  border-radius: 27px;
  position:absolute;
  float:left;
  top:0px;
  left:0px;
  z-index: 10;

}
.sound-thumb {
  /* border-radius: 27px;
  position: absolute; */
  top: 0px;
  left: 0px;
  z-index: 1;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
