<template>
  <div>
    <span id='cwppButton'></span>
    
    </div>
</template>

<script>

export default {
  name: 'ConnectWithPayPal',
  props: {
    appid: {
      type: String,
      required: true
    },
    authend: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      //isPlaying: false,
    };
  },
  mounted() {
      const vueContext = this;
      window.paypal.use(['login'],function (login) {
          login.render({
            "appid": vueContext.appid,
            "authend": vueContext.authend,
            "scopes":"openid profile email",
            "containerid":"cwppButton",
            "responseType":"code id_Token",
            "locale":"en-us",
            "buttonType":"CWP",
            "buttonShape":"rectangle",
            "buttonSize":"md",
            "fullPage":"true",
            "returnurl":"http://hustlesounds.com/#/account"
          });
        });
  },
  methods: {

  },
  computed: {

}
}
</script>

<style scoped>
</style>
