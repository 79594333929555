<template>
  <span>
    <span v-if="!beta">
      <b-button class="btn btn-lg btn-outline-primary" style="background-color:white;" @click="googleLogin()">
        <img class="google-icon-svg" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
        {{ buttonText }}
      </b-button>
      <div class="login-error"> {{ loginError }} </div>
    </span>
  </span>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'GoogleLogin',
  data() {
    return {
      loginError: "",
      betaEmail: "",
    };
  },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    beta: {
      type: Boolean,
      required: false
    }
  },
  mounted() {
  },
  methods: {
    googleLogin() {
      console.log("initiating firebase auth");
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then((userCredential) => {
        console.log(userCredential);
        this.$router.replace('claim');
      }).catch((err) => {
        this.loginError = err.msg;
        console.log(err);
      });

    },
  },
  computed: {
    betaStatus() {
      if (this.$route.query.code && this.betaPasswords.includes(this.$route.query.code.toLowerCase())) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
.google-icon-svg {
    position: relative;
    margin-top: -2px;
    margin-right: 4px;
    width: 18px;
    height: 18px;
    user-select: none
}
.btn-outline-primary:hover {
  color: black !important;
}
</style>
