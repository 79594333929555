<template>
  <div class="container col-md-6" style="min-height:800px;">
    <div v-show="loadingWL" class="text-center mt-5">
        <font-awesome-icon class="mr-1" :icon="['fa', 'sync']" spin /> Loading...
    </div>
    <div v-if="onWaitlist && !loadingWL">
      
      <h1 class="text-dark">Waitlist</h1>
      <p class="text-dark">Hustle Sounds is currently invite-only. Your account has not yet been authorized to claim songs. Please apply for access below, or contact the Hustle Sounds team if you believe this is a mistake.</p>
      <p><iframe class="typeform-widget" src="https://form.typeform.com/to/BKs6v8Oo?typeform-medium=embed-snippet"></iframe></p>

    </div>
    <div v-if="!onWaitlist">
      <h1 class="text-dark">{{ msg }}</h1>
      <p class="text-dark">Each of the songs below is unique. After you claim a song, you'll start earning a royalty share from its use in your videos.</p>
    
      <div v-show="!loadingSongs">
        <b-list-group>
          <b-list-group-item v-for="song in songsToPreview" :key="song.id" class="bg-transparent border border-transparent">

            <SoundRow>
              <template v-slot:soundImage><SoundImage :songObject="song"></SoundImage></template> 
              <template v-slot:primaryText>
                <p class="text-sm text-muted mb-0">Lo-Fi Hip Hop</p> <span class="text-success">●</span> <small>Available</small>
                </template> 
              <template v-slot:buttonLogic>
                <b-button class="btn-lg" variant="primary" @click="claimModal(song.id)"><font-awesome-icon class="mr-2" :icon="['fa', 'plus-square']" />Claim</b-button>   
              </template> 
            </SoundRow>
            
              <b-modal title="Claim Sound" class="modal-fullscreen" hide-footer :id="`claim-modal-${song.id}`"  @hide="hideClaimModal(song.id)">
                
            <SoundRow>
              <template v-slot:soundImage><SoundImage :songObject="song"></SoundImage></template> 
              <template v-slot:primaryText>
                <p class="text-md text-dark mb-0">{{ soundName }}</p> <small> {{ soundArtist }}</small>
                </template> 
            </SoundRow>
                <hr>

                <div v-show="!showConfirm && !showDone" class="name-screen">
                <label for="sound-name" class="text-dark">Pick a name for this sound:</label>

                  <b-form-input id="sound-name" class="text-dark border border-dark" v-model="soundName" placeholder=""></b-form-input>

                  <label for="sound-artist" class="mt-3 text-dark">Enter your name, username, or @handle:</label><br />
                  <b-form-input class="text-dark border border-dark" v-model="soundArtist" placeholder=""></b-form-input>

                    <b-alert show dismissible variant="warning" class="mt-2 mb-2">
                      <font-awesome-icon class="mr-1" :icon="['fa', 'exclamation-triangle']" /> Only use your name, @handle, or username that uniquely belongs to you. <strong>Attempting to use the name of a well-known artist, public figure, or influencer is prohibited.</strong>
                    </b-alert>

                  <span v-if="namesErrorMsg.length > 0" class="text-primary">{{ namesErrorMsg }}</span>
                  <div class="text-center mt-3">
                  <b-button variant="primary" @click="handleContinue()">Continue</b-button>
                  </div>
                </div>

                <div v-show="showConfirm" class="confirmation-screen text-left">


                    <div class="text-dark mt-3 mb-3">
                      When you click "Claim and Submit" below, this sound will be registered with networks like YouTube, TikTok, and Instagram/Facebook and its ongoing royalty share will be credited to your account.

                    </div>
                    <b-button :disabled="preventRefresh" class="mt-2" variant="primary" @click="claimHandler(song.id)">Claim and Submit</b-button>
                    <br />
                    <b-button variant="secondary" class="btn btn-light btn-sm mt-1" @click="showConfirm = !showConfirm">Go Back</b-button>

                </div>

                <div v-show="showDone" class="confirmation-screen">
                  <div class="text-left">
                    <h3 style="margin-top:1px">Success!</h3>
                    <h4>Download your sound for immediate use:</h4>
                    
                    <a :href="song.mp3_file.slice(1, -1)" target="_blank" class="mr-3">
                      <b-button variant="primary"><font-awesome-icon class="mr-1" :icon="['fa', 'download']" /> MP3</b-button>
                    </a>
                    <a v-if="isPro" :href="song.wav_file.slice(1, -1)" target="_blank">
                      <b-button variant="primary"><font-awesome-icon class="mr-1" :icon="['fa', 'download']" /> WAV</b-button>
                    </a>
                    <b-button v-if="!isPro" @click="showUpgradeModal()">
                      <font-awesome-icon class="mr-1" :icon="['fa', 'crown']" /> Upgrade for WAV
                    </b-button>
                    
                    <hr>

                    <div class="mt-3">
                      <b-button class="float-right" variant="outline-dark" @click="navigateToLibrary()">My Sounds</b-button>
                      <b-button class="float-left" variant="outline-dark" @click="hideClaimModalAndRefresh(song.id)">Claim Another</b-button>
                    </div>
                  </div>

                </div>


              </b-modal>

          </b-list-group-item>
        </b-list-group>

        <div class="text-center mt-2 mb-5">
            <b-button :disabled="preventRefresh" variant="outline-dark" @click="refreshSoundOptions()"> <font-awesome-icon class="mr-2" :icon="['fa', 'sync']" /> Refresh Options</b-button>
        </div>
      </div>
      <div v-show="loadingSongs" class="text-center">
        <font-awesome-icon class="mr-1" :icon="['fa', 'sync']" spin /> Loading...
      </div>
    </div>
  </div>
</template>

<script>
import SoundImage from '@/components/SoundImage';
import SoundRow from '@/components/SoundRow';

export default {
  name: 'Claim',
  components: {
    SoundImage,
    SoundRow
  },
  props: {
    //msg: String
  },
  data() {
    return {
      msg: "Claim Sounds",
      songsToPreview: [],
      songsToClear: [],
      preventRefresh: false,
      randomFactor: 6,
      numberSongs: 3,
      soundName: "",
      soundArtist: "",
      namesErrorMsg: "",
      showConfirm: false,
      showDone: false,
      userSongs: [],
      userStatus: "",
      loadingSongs: false,
      loadingWL: true,
      onWaitlist: true
    };
  },
  created() {
    window.addEventListener('beforeunload', this.clearBeingPreviewed)  
  },
   beforeDestroy() {
      this.clearBeingPreviewed();
  },
  mounted() {
    this.getWaitlistStatus();
  },
  methods: {
    getWaitlistStatus() {
      
      const vueContext = this;
      const userEmail = this.$store.state.currentUser.email;
      this.loadingWL = true;

      vueContext.$store.state.firestore.collection('waitlist')
      .where('email', '==', userEmail)
        .get()
        .then(querySnapshot => {
          const documents = querySnapshot.docs.map(doc => doc.data());
          if (documents.length > 0) {
            this.onWaitlist = false;
            this.loadingWL = false;
            this.refreshSoundOptions();
            this.getUserTracks();
            this.getUserStatus();
          } else {
            this.loadingWL = false;
          }
        })
    },
    clearBeingPreviewed() {
      //free up tracks that were just previewed by this user
      const vueContext = this;
      vueContext.songsToClear.forEach(function(item){
        let thisId = item.id.toString();
        vueContext.$store.state.firestore.collection('songs').doc(thisId).update({
              being_previewed: false
            }).then(() => {
                console.log("set being_previewed: false on "+ thisId);
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
        });
      });
      vueContext.songsToClear = [];

    },
    getAvailableTracks() {
      const vueContext = this;
      vueContext.songsToPreview = [];
      vueContext.preventRefresh = true;

      //let randomOffset = Math.floor(Math.random() * 10) + 1;

      vueContext.$store.state.firestore.collection('songs')
      .where('claimed', '==', false)
      .where('being_previewed', '==', false)
      .limit(vueContext.randomFactor)
        .get()
        .then(querySnapshot => {
          
          const documents_snapshot = querySnapshot.docs.map(doc => doc.data());
          const documents_shuffled = documents_snapshot.sort(function(){return .5 - Math.random()});
          const documents = documents_shuffled.slice(0,vueContext.numberSongs);

          documents.forEach(function(item){
            //update being_previewed state so other users don't see these same songs
            //TODO double-check correct security settings for this

            let thisId = item.id.toString();
            
              vueContext.$store.state.firestore.collection('songs').doc(thisId).update({
                being_previewed: true
              }).then(() => {
                console.log("set being_previewed: true on "+ thisId);
                  vueContext.songsToPreview.push(item);
                  vueContext.songsToClear.push(item);

                  if (vueContext.songsToPreview.length > vueContext.numberSongs-1) {
                    vueContext.preventRefresh = false;
                    vueContext.loadingSongs = false;
                  }

              })
              .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
              });


          });

          //console.log(documents);
        })
    },
    refreshSoundOptions() {
      //free up tracks that were just previewed by this user
      this.loadingSongs = true;
      this.clearBeingPreviewed();
      //get tracks
      this.getAvailableTracks();

    },
    showUpgradeModal() {
      this.$bvModal.show(`upgrade-modal`);
    },
    claimModal(songId) {
      this.getUserTracks();
      this.getUserStatus();
      // console.log(this.userTracksNumber);
      // console.log(this.claimLimit);
      // console.log(this.isPro);
      if (this.userTracksNumber >= this.claimLimit && !this.isPro) {
        
        this.$bvModal.show(`upgrade-modal`);
        //alert("gotta upgrade broh");

      } else {
        //console.log(this.userStatus);
        this.$bvModal.show(`claim-modal-${songId}`);
      }
    },
    hideClaimModal(songId) {
      this.$bvModal.hide(`claim-modal-${songId}`);
      this.namesErrorMsg = ""
    },
    hideClaimModalAndRefresh(songId) {
      this.$bvModal.hide(`claim-modal-${songId}`);
      this.showConfirm = false;              
      this.showDone = false;
      this.namesErrorMsg = "";
      this.soundName = "";
      this.soundArtist = "";
      this.refreshSoundOptions();
    },
    navigateToLibrary() {
      this.$router.push({ path: '/my-sounds' })
    },
    handleContinue() {
      if (this.validateName(this.soundName) && this.validateName(this.soundArtist)) {
        this.namesErrorMsg = ""
        this.showConfirm = true;
      } else {
        this.namesErrorMsg = "Please enter valid names before claiming. Names can only contain letters and numbers."
      }
    },
    claimHandler(songId) {

      const userId = this.$store.state.currentUser.uid;
      const vueContext = this;
      vueContext.preventRefresh = true;

        //update the song queue to claimed_by this user
        this.$store.state.firestore.collection('songs').doc(songId.toString()).update({
              claimed: true,
              claimed_by: userId,
              song_name: vueContext.soundName,
              artist_name: vueContext.soundArtist,
              status: "Pending Release"
          }).then(() => {
              //console.log(`claimed ${songId} for ${userId}`);
              vueContext.preventRefresh = false;
              vueContext.showConfirm = false;              
              vueContext.showDone = true;
          })
          .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
          });
    },
    isEmptyOrSpaces(str){
      return str === null || str.match(/^ *$/) !== null;
    },
    isAlphanumeric(str) { 
      var letters = /^[a-z\d\-_\s]+$/i;
      if(!str.match(letters)) {
      return false;
      } else {
      return true;
      }
    }, 
    validateName(name) {

      if (this.isAlphanumeric(name) && !this.isEmptyOrSpaces(name)) {
        return true;
      } else {
        return false;
      }

    },
    getUserTracks() {
      const vueContext = this;
      const userId = this.$store.state.currentUser.uid;

      vueContext.$store.state.firestore.collection('songs')
      .where('claimed', '==', true)
      .where('claimed_by', '==', userId)
        .get()
        .then(querySnapshot => {
          const documents = querySnapshot.docs.map(doc => doc.data());
          this.userSongs = documents;
          // console.log(documents);
        })
    },
    getUserStatus() {
        this.$store.state.firestore.collection('users').doc(this.currentUserID).get().then((doc) => { this.userStatus = doc.data().user_status; });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    currentUserID() {
      return this.$store.state.currentUser.uid;
    },
    userTracksNumber() {
      return this.userSongs.length;
    },
    isPro() {
      if (this.userStatus.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    claimLimit() {
      if (this.userStatus.length == 0) {
        return 50;
      } else {
        return 100;
      }
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.typeform-widget {
  width: 100%; 
  height: 700px;
  border:0;
  overflow:scroll;
}
.modal-fullscreen .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;
}
h1 {
  margin: 40px 0 0;
  font-size: 2em;

}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
