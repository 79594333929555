<template>
  <div class="container col-md-6" style="min-height:800px;">
    <h1 class="text-dark mb-2">{{ msg }}</h1>

<h2>Earnings</h2>

    <div class="row">
      <div class="col-lg-6">
        <div class="card border border-success">
          <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase mb-0 text-dark">Available To Withdraw</h5> <span class="h2 font-weight-bold mb-0">$0</span> </div>
              <div class="col-auto hide">
                <div class="icon icon-shape bg-primary text-white rounded-circle shadow">  <font-awesome-icon :icon="['fab', 'facebook']" /> </div>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm hide"> <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span> <span class="text-nowrap">Since last month</span> </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card border border-success">
          <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-dark mb-0">All-Time</h5> <span class="h2 font-weight-bold mb-0">$0</span> </div>
              <div class="col-auto hide">
                <div class="icon icon-shape bg-primary text-white rounded-circle shadow">  <font-awesome-icon :icon="['fab', 'instagram']" /> </div>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm hide"> <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span> <span class="text-nowrap">Since last month</span> </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card bg-default" style="margin:0 !important;">
    <div class="card-body">
      <div class="row justify-content-between align-items-center">
        <div class="col">
           <font-awesome-icon class="mr-1" :icon="['fab', 'paypal']" />
        </div>
        <div class="col-auto hide">
          <span class="badge badge-lg badge-success">Active</span>
        </div>
      </div>
      <div class="my-4">
        <span class="h6 surtitle text-muted">
          Connect PayPal
        </span>
        <span v-show="userPayPalEmail.length < 1">
          <p class="text-white mt-2 mb-2">Sync your PayPal account to withdraw earnings</p>

            <ConnectWithPayPal
              class="hide"
              appid="AbTKhYcG4rY0a4bu0Gg52XeKW6osqdAPKpPoZ3WIuf42rPoE5jt1nw8ZOh312ukDxtZO7rxRMh76muod"
              authend="sandbox"
            ></ConnectWithPayPal>
          
        </span>
        <span v-show="userPayPalEmail.length > 1">
          <p class="text-white mt-2 mb-2">
          Verified: {{ userPayPalEmail }}
          </p>
        </span>
      </div>
      <div class="row hide">
        <div class="col">
          <span class="h6 surtitle text-muted">Name</span>
          <span class="d-block h3">John Snow</span>
        </div>
      </div>
    </div>
</div>

<br />

<h2>Stats</h2>
<div class="row">
	<div class="col-lg-6">
		<div class="card card-stats border border-primary">
			<!-- Card body -->
			<div class="card-body">
				<div class="row">
					<div class="col">
						<h5 class="card-title text-uppercase text-muted mb-0">YouTube Views</h5> <span class="h2 font-weight-bold mb-0">PENDING</span> </div>
					<div class="col-auto">
						<div class="icon icon-shape bg-primary text-white rounded-circle shadow">  <font-awesome-icon :icon="['fab', 'youtube']" /> </div>
					</div>
				</div>
				<p class="mt-3 mb-0 text-sm hide"> <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span> <span class="text-nowrap">Since last month</span> </p>
			</div>
		</div>
	</div>
	<div class="col-lg-6">
		<div class="card card-stats border border-primary">
			<!-- Card body -->
			<div class="card-body">
				<div class="row">
					<div class="col">
						<h5 class="card-title text-uppercase text-muted mb-0">TikTok Views</h5> <span class="h2 font-weight-bold mb-0">PENDING</span> </div>
					<div class="col-auto">
						<div class="icon icon-shape bg-primary text-white rounded-circle shadow">  <font-awesome-icon :icon="['fab', 'tiktok']" /> </div>
					</div>
				</div>
				<p class="mt-3 mb-0 text-sm hide"> <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span> <span class="text-nowrap">Since last month</span> </p>
			</div>
		</div>
	</div>
</div>
<div class="row mb-4">
	<div class="col-lg-6">
		<div class="card card-stats border border-primary">
			<!-- Card body -->
			<div class="card-body">
				<div class="row">
					<div class="col">
						<h5 class="card-title text-uppercase text-muted mb-0">Facebook Views</h5> <span class="h2 font-weight-bold mb-0">PENDING</span> </div>
					<div class="col-auto">
						<div class="icon icon-shape bg-primary text-white rounded-circle shadow">  <font-awesome-icon :icon="['fab', 'facebook']" /> </div>
					</div>
				</div>
				<p class="mt-3 mb-0 text-sm hide"> <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span> <span class="text-nowrap">Since last month</span> </p>
			</div>
		</div>
	</div>
	<div class="col-lg-6">
		<div class="card card-stats border border-primary">
			<!-- Card body -->
			<div class="card-body">
				<div class="row">
					<div class="col">
						<h5 class="card-title text-uppercase text-muted mb-0">Instagram Views</h5> <span class="h2 font-weight-bold mb-0">PENDING</span> </div>
					<div class="col-auto">
						<div class="icon icon-shape bg-primary text-white rounded-circle shadow">  <font-awesome-icon :icon="['fab', 'instagram']" /> </div>
					</div>
				</div>
				<p class="mt-3 mb-0 text-sm hide"> <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span> <span class="text-nowrap">Since last month</span> </p>
			</div>
		</div>
	</div>
</div>

<hr>


<br />

    <div class="card border border-primary mt-2" style="margin:0 !important;" v-show="!isPro">
      <!-- Card body -->
      <div class="card-body">
        <div class="row align-items-center">
          <p class="text-primary">Have a special code? Enter it here.</p>
          <b-input v-model="specialCode" variant="outline-primary" :placeholder="specialCodePlaceholder"></b-input>
          <b-button class="mt-1" variant="outline-primary" @click="handleSpecialCode()">Submit</b-button>
        </div>
      </div>
    </div>

    <b-modal class="modal-fullscreen" hide-header hide-footer id="special-code-success">
      You must be pretty special - your account has been upgraded!
    </b-modal>
    <b-modal class="modal-fullscreen" hide-header hide-footer id="special-code-fail">
      Sorry, that's not right.
    </b-modal>

    <b-modal class="modal-fullscreen" no-close-on-backdrop hide-header hide-footer id="processing-paypal">
      {{ paypalText }}
    </b-modal>
<br />
<br />
  </div>
</template>

<script>
import ConnectWithPayPal from '@/components/ConnectWithPayPal';
import axios from 'axios';

export default {
  name: 'Account',
  components: {
    ConnectWithPayPal
  },
  props: {
    //msg: String
  },
  data() {
    return {
      msg: "My Account",
      specialCodes: ['freebeta','doge'],
      specialCodePlaceholder: "Enter code",
      specialCode: "",
      userStatus: "",
      userPayPalEmail: "",
      userData: {
        streamingData: {
          youtube: null,
          tiktok: null,
          fb: null,        
        },
        earningsData: {
          available: null,
          all: null,
        }
      },
      paypalCode: null,
      paypalText: "Verifying PayPal..."
    };
  },
  created() {

  },
  beforeDestroy() {

  },
  mounted() {
    this.getUserStatus();
    this.getUserStats();
  },
  methods: {
    getUserStats() {
      // const addMessage = this.$firebase.functions().httpsCallable('addMessage');
      // addMessage().then((result) => {
      //     // Read result of the Cloud Function.
      //     console.log(result);
      //   });
    },
    getPayPalUser() {
      axios.get(`https://us-central1-hustle-sounds.cloudfunctions.net/paypalUser?code=${this.paypalCode}`)
        .then((res) => {
          this.$bvModal.show("processing-paypal");
          if (res.data.name && res.data.emails) {
              this.$store.state.firestore.collection('users').doc(this.currentUserID).update({
                  paypal: res.data
              })
              .then(() => {
                this.paypalText = "Success!";
                this.$bvModal.hide("processing-paypal");
                this.getUserStatus();
              })
              .catch(() => {
                this.paypalText = "There was an error verifying your PayPal information. Please try again or contact help@hustlesounds.com if this keeps happening.";
              });

          } else {
            this.paypalText = "There was an error verifying your PayPal information. Please try again or contact help@hustlesounds.com if this keeps happening.";
          }
        });
    },
    getUserStatus() {
        this.$store.state.firestore.collection('users').doc(this.currentUserID).get().then((doc) => { 
          this.userStatus = doc.data().user_status; 
          if (doc.data().paypal.emails) {
            this.userPayPalEmail = doc.data().paypal.emails[0].value;
          } else {
            if (this.$route.query.code) {
              this.paypalCode = this.$route.query.code;
              this.getPayPalUser();
            }
          }
        });
    },
    handleSpecialCode() {
      if (this.specialCodes.includes(this.specialCode.toLowerCase())) {
        this.$store.state.firestore.collection('users').doc(this.currentUserID).update({
            user_status: this.specialCode
        })
        .then(() => {
          this.$bvModal.show("special-code-success");
          location.reload(); //I know, I know, we'll fix it later
        })
        .catch(() => {
          this.$bvModal.show("special-code-fail");
        });
      } else {
        this.$bvModal.show("special-code-fail");
      }
    }
  },
  computed: {
    isPro() {
      if (this.userStatus.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    currentUserID() {
      return this.$store.state.currentUser.uid;
    },
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin: 40px 0 0;
  font-size: 2em;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
