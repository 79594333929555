<template>
  <div>
    <div class="card border border-primary" style="margin:0 !important;">
      <!-- Card body -->
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- Avatar -->
            <slot name="soundImage"></slot>
          </div>
          <div class="col ml--2">
            <slot name="primaryText"></slot>
          </div>
          <div class="col-auto mx-auto mt-1">
            <slot name="buttonLogic"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SoundRow',
}
</script>

<style scoped>

</style>
