import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import firebase from 'firebase'
import { firestorePlugin } from 'vuefire'

//fontawesome 5
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlay,faPause,faPlayCircle,faPauseCircle,faCoins,faCrown,faFilm,faLock,faPlusSquare,faMusic,faUserCircle,faSync,faExclamationTriangle,faDownload } from '@fortawesome/free-solid-svg-icons'
import { faPaypal,faYoutube,faTiktok,faInstagram,faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Dashboard from '@/plugins/dashboard-plugin'
Vue.use(Dashboard);

Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(faPlay);
library.add(faPause);
library.add(faPlayCircle);
library.add(faPauseCircle);
library.add(faFilm);
library.add(faCoins);
library.add(faCrown);
library.add(faLock);
library.add(faPlusSquare);
library.add(faMusic);
library.add(faUserCircle);
library.add(faSync);
library.add(faExclamationTriangle);
library.add(faDownload);
library.add(faPaypal);
library.add(faYoutube);
library.add(faTiktok);
library.add(faInstagram);
library.add(faFacebook);

//views
import Account from '@/views/Account'
import Library from '@/views/Library'
import Claim from '@/views/Claim'
import Home from '@/views/Home'
import Admin from '@/views/Admin'


//routes
Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    component: Home 
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-sounds',
    name: 'My Sounds',
    component: Library,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/claim',
    name: 'Claim',
    component: Claim,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  }

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next('/?needs_login=1');
  else if (!requiresAuth && currentUser) next('my-sounds');
  else next();
});

//store

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count: 0,
    currentUser: '',
    firestore: null,
    currentUserRecord: null,
    waitlist: true
  },
  mutations: {
    increment(state) {
      state.count++
    },
    updateCurrentUser(state,value) {
      state.currentUser = value;
    },
    updateFirestore(state,value) {
      state.firestore = value;
    },
    updateUserRecord(state,value) {
      state.currentUserRecord = value;
    },
    updateWaitlist(state,value) {
      state.currentUserRecord = value;
    }
  }
})

//firestore database

Vue.use(firestorePlugin);

//auth

let app = '';
const config = {
  apiKey: 'AIzaSyCMn20KcDXfWTJvCgvFfj6OrbhQ4GKeBnQ',
  authDomain: 'hustlesounds.com',
  databaseURL: 'https://hustle-sounds.firebaseio.com',
  projectId: 'hustle-sounds',
  storageBucket: 'hustle-sounds.appspot.com',
  messagingSenderId: '574629600616'
};

firebase.initializeApp(config);

const db = firebase.app().firestore();
store.commit('updateFirestore',db);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    //check for user record in firestore

    store.state.firestore.collection('users').doc(user.uid).get().then((doc) => {
      if (doc.exists) {
          //console.log("Document data:", doc.data());
          //console.log("found user in datastore")
          //add user record to store          
          store.commit('updateUserRecord',doc.data());
          //add user status to user object in store
          user.user_status = doc.data().user_status;
      } else {
        //if not, create user firestore object
        //console.log("adding new user to datastore")
        const newRecord = {
          user_status: "default_pro",
          paypal: ""
        }
        user.user_status = newRecord.user_status;
        store.state.firestore.collection('users').doc(user.uid).set(newRecord);
        store.commit('updateUserRecord',newRecord);
    }
    });

    //add currentUser object to store
    store.commit('updateCurrentUser',user);

  }
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});
