<template>
  <div class="container col-md-6" style="min-height:800px;">
    <h1>{{ msg }}</h1>

<hr>
    <h4>Distro Tracks</h4>
    <p>Copy and paste CSV values from user_songs. First line headers ignored.</p>
    <textarea v-model="distroTextarea">

    </textarea><br />
        <b-form-select v-model="selectedWriteGenre" :options="genres"></b-form-select>
    <br /><br />
    <b-button variant="primary" @click="importTracks()">{{ importTracksStatus }}</b-button>

<hr>
    <h4>Claimed Songs</h4>

      <b-list-group>
        <b-list-group-item v-for="track in claimedTracks" :key="track.id" v-show="!excludeAdmins.includes(track.claimed_by) && pendingStatuses.includes(track.status)" class="border border-1 mt-4">

          <h5>Song Name</h5>
            <b-form-input type="text" :value="track.song_name"></b-form-input>
            <h5 class="mt-2">Artist Name</h5>
            <b-form-input type="text" :value="track.artist_name"></b-form-input>
            <h5 class="mt-2">Status</h5>
            <b-form-input type="text" :value="track.status"></b-form-input>
            <h5 class="mt-2">User</h5>
            <b-form-input type="text" :value="track.claimed_by"></b-form-input>
          <hr>
            <a :href="track.wav_file.slice(1, -1)" target="_blank">
              <b-button variant="primary"><font-awesome-icon class="mr-1" :icon="['fa', 'download']" /> WAV</b-button>
            </a>
            <a class="ml-3" :href="track.wav_file.slice(1, -1)" target="_blank">WAV Link</a>
          <hr>
            <b-button variant="danger" v-on:click="setActive(track.id)">Set Active</b-button>


        </b-list-group-item>
      </b-list-group>

    <h4 class="mt-5">Active Songs</h4>

      <b-list-group>
        <b-list-group-item v-for="track in claimedTracks" :key="track.id" v-show="!excludeAdmins.includes(track.claimed_by) && activeStatuses.includes(track.status)" class="border border-1 mt-4">

          <h5>Song Name</h5>
            <b-form-input type="text" :value="track.song_name"></b-form-input>
            <h5 class="mt-2">Artist Name</h5>
            <b-form-input type="text" :value="track.artist_name"></b-form-input>
            <h5 class="mt-2">Status</h5>
            <b-form-input type="text" :value="track.status"></b-form-input>
            <h5 class="mt-2">User</h5>
            <b-form-input type="text" :value="track.claimed_by"></b-form-input>
          <hr>
            <a class="ml-3" :href="track.wav_file.slice(1, -1)" target="_blank">WAV Link</a>


        </b-list-group-item>
      </b-list-group>

  </div>
</template>

<script>
export default {
  name: 'Admin',
  props: {
    //msg: String
  },
  data() {
    return {
      msg: "Many Secret",
      distroTextarea: "",
      selectedWriteGenre: 'lofi',
      genres: [
        { value: 'lofi', text: "lofi" },
        { value: 'edm', text: "edm" },
      ],
      importTracksStatus: "Import Tracks",
      claimedTracks: null,
      excludeAdmins: ["7K0m0WYRWfYRv9grU0GMyIR1Mrc2-disabled","m9F34mFz7jOYZnaF2fhAa6QGPm82-disabled"],
      pendingStatuses: ["Pending Release","pending_release"],
      activeStatuses: ["Active"]
    };
  },
  mounted() {
    if (this.$store.state.currentUser.email != 'alex@boomy.com') {
      alert("be gone!");
      this.$router.replace('/claim');
    }
    this.getClaimedTracks();
  },
  methods: {
    getClaimedTracks() {
      const vueContext = this;
      // vueContext.songsToPreview = [];
      // vueContext.preventRefresh = true;

      //let randomOffset = Math.floor(Math.random() * 10) + 1;

      vueContext.$store.state.firestore.collection('songs')
      .where('claimed', '==', true)
        .get()
        .then(querySnapshot => {
          
          const documents = querySnapshot.docs.map(doc => doc.data());
          vueContext.claimedTracks = documents;
          //console.log(documents);

        })
    },
    setActive(id) {
      console.log(id);
      const vueContext = this;
      let thisId = id.toString();
      vueContext.$store.state.firestore.collection('songs').doc(thisId).update({
              status: "Active"
            })
            .then(() => {
                console.log("Success");
                vueContext.getClaimedTracks();
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });

    },
    importTracks() {
      const vueContext = this;
      let songsarray = this.distroTextarea.split("\n");
      let writeGenre = this.selectedWriteGenre;

      songsarray.forEach(function(item, index){

        let songRow = item.split(",");

        if (index > 0 && songRow.length == 11) {

          let writeID = songRow[0];
          let write_artist_name = "";
          let write_song_name = "";
          let write_being_previewed = false;
          let write_claimed = false;
          let write_claimed_by = "";
          let write_genre = writeGenre;
          let write_mp3_file = songRow[4];
          let write_wav_file = songRow[3];
          let write_random_seed = Math.floor(Math.random() * 10000000) + 1;
          
          vueContext.$store.state.firestore.collection('songs').doc(writeID).set({
            id: parseInt(writeID),
            artist_name: write_artist_name,
            being_previewed: write_being_previewed,
            claimed: write_claimed,
            claimed_by: write_claimed_by,
            genre: write_genre,
            mp3_file: write_mp3_file,
            song_name: write_song_name,
            wav_file: write_wav_file,
            random_seed: write_random_seed
          });

          vueContext.importTracksStatus = "Importing "+writeID;

        }
      });
      
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
textarea {
  width: 500px;
  height: 200px;
} 
select {
  width:500px;
}
</style>
