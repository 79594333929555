<template>
  <div>
    <section class="bg-primary hero header main-content text-center"><br />

    <div class="width-container">
          <h1 class="text-warning mt-3 mb-5" v-html="msg"></h1>

                <lottie class="product-image" :options="coinsOptions" :height="280" :width="280"  v-on:animCreated="handleAnimation"/>
          <br />

            <br />
                  <stats-card class="bg-dark text-left text-primary">
                      <!-- Card body -->
                      <div class="row">
                          <div class="col-auto mt-3">
                              <div class="icon icon-shape bg-warning text-dark rounded-circle shadow mt-1">
                                  <strong>1</strong>
                              </div>
                          </div>                    
                          <div class="col mt-2">
                              <span class="h1 font-weight-bold mb-0 text-white">Claim original songs for free</span>
                          <p class="mt-2 mb-0 text-sm">
                              <span class="text-light mr-2">NO TAKEDOWNS OR LICENSE EXPIRATIONS</span>
                          </p>
                          </div>
                      </div>
                  </stats-card>
                  <stats-card class="bg-dark text-left text-primary">
                      <!-- Card body -->
                      <div class="row">
                          <div class="col-auto mt-3">
                              <div class="icon icon-shape bg-warning text-dark rounded-circle shadow mt-1">
                                  <strong>2</strong>
                              </div>
                          </div>                    
                          <div class="col mt-2">
                              <span class="h1 font-weight-bold mb-0 text-white">Use songs in your videos or podcasts</span>
                          <p class="mt-2 mb-0 text-sm">
                              <span class="text-light mr-2">YOUTUBE TIKTOK INSTAGRAM FACEBOOK VIMEO AND MORE</span>
                          </p>
                          </div>
                      </div>
                  </stats-card>
                  <stats-card class="bg-dark text-left text-primary">
                      <!-- Card body -->
                      <div class="row">
                          <div class="col-auto mt-3">
                              <div class="icon icon-shape bg-warning text-dark rounded-circle shadow mt-1">
                                  <strong>3</strong>
                              </div>
                          </div>                    
                          <div class="col mt-2">
                              <span class="h1 font-weight-bold mb-0 text-white">Earn music royalty share</span>
                          <p class="mt-2 mb-0 text-sm">
                              <span class="text-light mr-2">HUSTLE SOUNDS PAYS YOU A SHARE OF MUSIC ROYALTIES</span>
                          </p>
                          </div>
                      </div>
                  </stats-card>
            <br />

          <GoogleLogin buttonText="Sign In With Google"></GoogleLogin>
            <br />
    </div>

    </section>
    <section class="main homepage" v-bind:style="moneyBackground">

    <div class="container text-center justify-content-md-center">
      <br /><br />
          <card
            class="mb-2 rounded text-white calculator-container"
            width="100%"
            gradient="secondary"
          >
          <br /><br />
            <b-card-text>
              <p>How many videos do you post per week?</p>
              <center>
              <b-input @change="calculateAnnualRevenue()" variant="" v-model="vidsPerWeek" class="form-control-alternative text-dark calculator-input"/>
              <b-form-input @change="calculateAnnualRevenue()" id="videos-per-week" v-model="vidsPerWeek" type="range" min="0" max="35" class="calculator-input"></b-form-input>
              <br /><br />
              <p>How many views do your videos recieve on average?</p>

              <b-input @change="calculateAnnualRevenue()" variant="" v-model="viewsPerVid" class="form-control-alternative text-dark calculator-input"/>
              <b-form-input @change="calculateAnnualRevenue()" id="views-per-video" v-model="viewsPerVid" type="range" min="0" max="1000000" step="1000" class="calculator-input"></b-form-input>
              </center>
            </b-card-text>
              <br />
              <p>You could be making up to...</p>
              <h1 class="text-success revenue-callout">${{ annualRevenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</h1>
              <p>...annually just by using Hustle Sounds in your videos</p>

          </card>
    </div>




      <br /><br />

    </section>

    <section class="bg-primary">
      <div class="container p-5 text-white explainer text-center">
        <div class="mb-2">
          <lottie :options="plantOptions" :height="280" :width="280"  v-on:animCreated="handleAnimation"/>
          <h1 class="text-warning mt-5"><strong>How It Works</strong></h1>
        </div>
        <ul class="bullets mt-5">
          <li><font-awesome-icon class="explainer-icon" :icon="['fa', 'film']" /> <br /> Whenever music is used in videos, services like YouTube, TikTok, and Facebook pay royalties for the use of that music</li>
          <li><font-awesome-icon class="explainer-icon" :icon="['fa', 'coins']" /> <br /> When you use songs from Hustle Sounds in your videos, we claim those royalties and cut you into an 80% share</li>
          <li><font-awesome-icon class="explainer-icon" :icon="['fa', 'crown']" /> <br /> It's a no-brainer: just start using songs from Hustle Sounds anywhere in your videos, and you'll recieve regular payouts through PayPal</li>
        </ul>
        <div class="text-center">
            <GoogleLogin buttonText="Sign In With Google"></GoogleLogin>
        </div>

      </div>
    </section>


  </div>
</template>

<script>
import lottie from 'vue-lottie';
import GoogleLogin from '@/components/GoogleLogin';
import animationDataCoins from '@/assets/61559-finances-management.json';
import animationDataPlant from '@/assets/56819-newsletter.json';

export default {
  name: 'Home',
  components: {
    lottie,
    GoogleLogin
  },
  data() {
    return {
      msg: "Free stock music that pays you",
      baseRate: 0.00079,
      vidsPerWeek: 1,
      viewsPerVid: 5000,
      annualRevenue: 166,
      coinsOptions: {animationData: animationDataCoins},
      plantOptions: {animationData: animationDataPlant},
      moneyGifs: [
        "https://media.giphy.com/media/KiGMzfSIrNf0s/giphy.gif",
        "https://media.giphy.com/media/72HahsJD4atSE/giphy.gif",
        "https://media.giphy.com/media/12Eo7WogCAoj84/giphy.gif",
        "https://media.giphy.com/media/quRHm1REjjxtu/giphy.gif",
        "https://media.giphy.com/media/12pJ8OxSWwO86Y/giphy.gif",
        "https://media.giphy.com/media/xT0xeAqBxu7wd3Ie0o/giphy.gif",
        "https://media.giphy.com/media/l0MYResEdNIyniuL6/giphy.gif",
        "https://media.giphy.com/media/3oKIPbnhOJJVSGAbKM/giphy.gif",
        "https://media.giphy.com/media/l1BgSJsYKTEierBBK/giphy.gif",
        "https://media.giphy.com/media/Ne1A0kENxfSjm/giphy.gif"
      ],
      betaPasswords: ["boost","nyucatalyst","boomy","thehustleclub","thehustlehundred","hustlehundred","hustlehouse","onlyfewhustle","topcreatorhustle"],
      betaCode: "",
      betaEmail: "",
      betaFollowers: "",
      betaFormStatus: true,
      showBetaLogin: false
    };
  },
  mounted() {
        //console.log(this.$store.state)
  },
  methods: {
    submitBetaCode() {
      console.log(this.betaCode.toLowerCase());
      if (this.betaPasswords.includes(this.betaCode.toLowerCase())) {
        
        this.showBetaLogin = true;
        
      } else {
        alert("Sorry, that code doesn't look correct.");
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    calculateAnnualRevenue() {
      const calculateRevenue = this.vidsPerWeek*56*this.viewsPerVid*this.baseRate*.75;
      this.annualRevenue = calculateRevenue.toFixed(0);
      //console.log(this.annualRevenue);
      this.moneyGifs.sort();
    }
  },
  computed: {
    moneyBackground() {
      const randomGif = this.moneyGifs[Math.floor(Math.random() * this.moneyGifs.length)];
      //console.log(randomGif);
      return `background-image:url('${randomGif}');`;
    },
    betaStatus() {
      if (this.$route.query.code && this.betaPasswords.includes(this.$route.query.code.toLowerCase())) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.calculator-container {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  opacity:0.98;
}
.header-image {
  margin: 0px 0px 0px 0px !important;
  position: relative;
  overflow:hidden;
  left: 0px;
  height:200px;
}
h3 {
  margin: 4px 0 0;
  font-weight:400;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


.hero {
  padding:25px;
  font-size: 1rem;
}

.hero h1 {
  font-size:4em;
  font-weight:bold;
  line-height:1em;
  color:white;
  letter-spacing: -.1rem;
}

.bullets {
  font-size: 1.5em;
}
.bullets li {
  margin-bottom:50px;
}
.bullets ul {
  margin:0;
}
.revenue-callout {
  font-size:5rem;
  font-weight:900;
}
.homepage {
  background-color: black;
  background-repeat: repeat;
  background-size: 150px;
}
.calculator-input {
  width:250px;
}
.explainer h1 {
  font-size:2.5em;
  font-weight:700;
}
.explainer-icon {
  font-size:2em;
  margin-bottom:10px;
}
.calculator-container p {
  font-size:1.1em;
}

.width-container {
  max-width:700px !important;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 480px) {

.revenue-callout {
  font-size:3em;
  font-weight:900;
}

}

</style>
