<template>
  <div class="container col-md-6 mb-5" style="min-height:800px;">
    <h1 class="text-dark">{{ msg }}</h1>
    <p class="text-dark">Your claimed sounds are ready to use immediately. Songs may take days to weeks to fully register, but you can still post videos with them in the meantime.</p>

    <p class="mt-3" v-if="!loadingSounds && userSongs.length < 1">
      You haven't claimed any sounds yet. <b-button @click="navigateToClaim()">Claim a Sound</b-button>
    </p>

    <div v-show="loadingSounds" class="text-center">
      <font-awesome-icon class="mr-2" :icon="['fa', 'sync']" spin /> Loading...
    </div>

    <b-list-group>
      <b-list-group-item v-for="song in userSongs" v-show="song.status" :key="song.id">
        <span v-if="song.status">

          <SoundRow>
             <template v-slot:soundImage><SoundImage :songObject="song"></SoundImage></template> 
             <template v-slot:primaryText>
              <p class="text-sm text-muted mb-0">{{ song.song_name }} // {{ song.artist_name }} </p>  <span v-bind:class="{ 'text-primary': song.status == 'Active', 'text-danger': song.status != 'Active' }">●</span> <small>{{ song.status }}</small>
              </template> 
             <template v-slot:buttonLogic>

                  <a :href="song.mp3_file.slice(1, -1)" target="_blank" class="mr-3">
                    <b-button variant="primary"><font-awesome-icon class="mr-1" :icon="['fa', 'download']" /> MP3</b-button>
                  </a>
                  <a v-if="isPro" :href="song.wav_file.slice(1, -1)" target="_blank">
                    <b-button variant="primary"><font-awesome-icon class="mr-1" :icon="['fa', 'download']" /> WAV</b-button>
                  </a>
                  <b-button v-if="!isPro" @click="showUpgradeModal()">
                    <font-awesome-icon class="mr-1" :icon="['fa', 'crown']" /> Upgrade for WAV
                  </b-button>

                  <b-button @click="showModal(song.id)" variant="primary" class="hide">Instructions</b-button>

                  <b-modal class="modal-fullscreen" title="Instructions" hide-footer :id="`inst-modal-${song.id}`">
                    Instructions for: <b-form-select v-model="selectedInstructions" :options="instructions"></b-form-select>

                  </b-modal>

             </template> 
          </SoundRow>

        </span>
      </b-list-group-item>
    </b-list-group>
  
  </div>
</template>

<script>

import SoundImage from '@/components/SoundImage';
import SoundRow from '@/components/SoundRow';

export default {
  name: 'Library',
  components: {
    SoundImage,
    SoundRow
  },
  props: {
    //msg: String
  },
  data() {
    return {
      msg: "My Sounds",
      userSongs: [],
      instructions: [
        { value: 'youtube', text: "YouTube" },
        { value: 'tiktok', text: "TikTok" },
        { value: 'fb', text: "Facebook/Instagram" },
      ],
      selectedInstructions: 'youtube',
      userStatus: "",
      loadingSounds: false
    };
  },
  mounted() {
    //console.log();
    this.getUserTracks();
    this.getUserStatus();
  },
  methods: {
    showUpgradeModal() {
      this.$bvModal.show(`upgrade-modal`);
    },
    getUserTracks() {
      const vueContext = this;
      const userId = this.$store.state.currentUser.uid;
      this.loadingSounds = true;

      vueContext.$store.state.firestore.collection('songs')
      .where('claimed', '==', true)
      .where('claimed_by', '==', userId)
        .get()
        .then(querySnapshot => {
          
          const documents = querySnapshot.docs.map(doc => doc.data());
          vueContext.userSongs = documents;
          vueContext.loadingSounds = false;
          //console.log(documents);
        })
    },
    getUserStatus() {
        this.$store.state.firestore.collection('users').doc(this.currentUserID).get().then((doc) => { this.userStatus = doc.data().user_status; });
    },
    showModal(songId) {
      this.$bvModal.show(`inst-modal-${songId}`);
    },
    hideModal(songId) {
      this.$bvModal.hide(`inst-modal-${songId}`);
    },
    navigateToClaim() {
      this.$router.push({ path: '/claim' })
    },
  },
  computed: {
    isPro() {
      if (this.userStatus.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    currentUserID() {
      return this.$store.state.currentUser.uid;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin: 40px 0 0;
  font-size: 2em;

}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.btn {
  margin-top:5px;
}
</style>
